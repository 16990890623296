import {
  Box,
  BoxProps,
  Button,
  Container,
  Flex,
  FormProvider,
  Graphic,
  Heading,
  Icon,
  Image,
  LinkButton,
  Stack,
  Text,
  TextInput,
  useForm,
} from '@thirstycamel/ui'
import { useRouter } from 'next/router'
import queryString from 'qs'
import React from 'react'
import { useStore } from '../../store/hooks'
import Input from '../Input'

const lines = [
  'Great offers and discounts on your favourite liquor products',
  'The latest Thirsty Camel news delivered straight to your inbox',
  'Exclusive competitions & rewards',
  'Plus a little special something on your birthday!',
]

interface FormData {
  email: string
  firstName: string
  marketing: boolean
}

export interface HumpClubBannerProps extends BoxProps {}

export const HumpClubBanner = ({ ...restProps }: HumpClubBannerProps) => {
  const router = useRouter()

  const selectedStore = useStore(s => s.store.selectedStore)
  const location = useStore(s => s.location)

  const region = selectedStore?.region || location.region || 'VIC'
  const isCamelCard = region === 'SA' || region === 'NT'

  const form = useForm<FormData>()

  const handleSubmit = form.handleSubmit(data => {
    router
      .push({
        pathname: '/register',
        query: queryString.stringify(data),
      })
      .then(() => window.scrollTo(0, 0))
  })

  return (
    <Box py={[10, 8, 10, 12]} bg="pink.500" {...restProps}>
      <Container as={Flex} size="lg" justifyContent="center">
        <Flex
          align="flex-start"
          textAlign={[isCamelCard ? 'center' : null, 'left']}
          direction={[isCamelCard ? 'column' : 'row-reverse', 'row']}
        >
          {isCamelCard ? (
            <Image
              src="/static/images/camel-card.png"
              flex={['3 1 0', , '3 1 0', '4 1 0']}
              w="100%"
              maxWidth={['50%', '30%', '200px']}
              transform={['rotate(10deg)', 'rotate(-10deg)']}
              mr={['auto', 6]}
              ml={['auto', 0]}
            />
          ) : (
            <Graphic
              name="humpClubLogo"
              flex={['3 1 0', , '3 1 0', '4 1 0']}
              w="100%"
              maxWidth={['35%', '200px']}
              transform={['rotate(10deg)', 'rotate(-10deg)']}
              mr={[, 4]}
            />
          )}

          <Stack spacing={4} flex="6 1 0" align="flex-start" pr={[, , 8]}>
            <Heading color="white" textTransform="uppercase" fontSize="lg">
              {isCamelCard ? 'Save, Earn AND Win with Camel Card' : 'Join Hump Club'}
            </Heading>

            <Text display={[, , , 'none']} color="white" fontWeight="medium">
              {isCamelCard
                ? `Use your Camel Card every time you shop at your local Thirsty Camel and you'll earn Camel Cash for every dollar you spend. Plus keep an eye out for exclusive card holder bonus offers and watch you $ grow.`
                : `Join Thirsty Camel's Hump Club for great offers & discounts on your favourite liquor
              products.`}
            </Text>

            {!isCamelCard && (
              <Box display={[, , 'none']}>
                <LinkButton href="/register" variant="outline" variantColor="pink">
                  Join For Free
                </LinkButton>
              </Box>
            )}

            {isCamelCard ? (
              <Text display={['none', , , 'flex']} color="white" fontWeight="medium">
                Use your Camel Card every time you shop at your local Thirsty Camel and you'll earn
                Camel Cash for every dollar you spend. Plus keep an eye out for exclusive card
                holder bonus offers and watch your $ grow.
                <br />
                <br />
                What are you waiting for? Join the caravan today!
              </Text>
            ) : (
              <Stack as="ul" display={['none', , , 'flex']}>
                {lines.map(line => (
                  <Stack key={line} as="li" isInline spacing={3}>
                    <Icon name="check_outline" fontSize="xl" color="white" />
                    <Text color="white" fontWeight="medium" fontSize="sm">
                      {line}
                    </Text>
                  </Stack>
                ))}
              </Stack>
            )}

            {isCamelCard && (
              <LinkButton
                href="/page/[slug]"
                hrefAs="/page/camel-card"
                variant="outline"
                variantColor="pink"
                mt={4}
                mx={['auto', 0]}
              >
                Join For Free
              </LinkButton>
            )}
          </Stack>

          {!isCamelCard && (
            <FormProvider {...form}>
              <Stack
                as="form"
                flex={[, , , '5 0 0', '4 0 0']}
                spacing={4}
                onSubmit={handleSubmit}
                display={['none', , , 'flex']}
                mt={10}
              >
                <Input
                  as={TextInput}
                  isRequired
                  type="email"
                  name="email"
                  placeholder="EMAIL ADDRESS"
                  _placeholder={{ fontFamily: 'heading' }}
                  rules={{ required: 'Please enter your email address.' }}
                />

                <Flex direction={['column', 'row']}>
                  <Input
                    as={TextInput}
                    isRequired
                    name="firstName"
                    placeholder="FIRST NAME"
                    _placeholder={{ fontFamily: 'heading' }}
                    rules={{ required: 'Please enter your first name.' }}
                    mb={[5, 0]}
                    mr={[0, 5]}
                  />

                  <Button type="submit" variant="outline" variantColor="pink" flexShrink={0}>
                    Join for free
                  </Button>
                </Flex>
              </Stack>
            </FormProvider>
          )}
        </Flex>
      </Container>
    </Box>
  )
}

export default HumpClubBanner
