import React, { memo } from 'react'
import { NextPage } from 'next'
import { BackendQueryOptions, Box, Stack, useProductsQuery, Text } from '@thirstycamel/ui'

import { config } from '../utils/config'
import LayoutMain from '../layout/LayoutMain'
import SpecialsSection from '../components/SpecialsSection'
import HumpClubBanner from '../components/HumpClubBanner'
import HomeBanner from '../components/HomeBanner'
import Advert from '../components/Advert'
import TrendingKeywords from '../components/TrendingKeywords'
import { Context } from './_app'
import { QueryCache, useQuery } from 'react-query'
import { dehydrate } from 'react-query/hydration'
import { backend } from '../utils/backend'
import { parseCookies } from 'nookies'
import { BannersDocument, SearchTagsDocument } from '../../generated'
import { useStore } from '../store/hooks'
import type CoreProductEntity from '@ts/core/src/modules/coreproduct/coreproduct.entity'
import imageProxy from '../utils/imageProxy'
import { fetchGraphQL } from '../utils/graphql-fetch'

import geoip from 'geoip-lite'
import requestIp from 'request-ip'
import { useUser } from '../hooks/useUser'
import { useApp } from '../hooks/useApp'

// This should be controlled via Portal
const sections = [
  {
    title: 'Beer',
    slug: 'beer',
  },
  {
    title: 'Premix',
    slug: 'premix',
  },
  {
    title: 'Spirits',
    slug: 'spirits',
  },
  {
    title: 'Red Wine',
    slug: 'red-wine',
  },
  {
    title: 'White Wine',
    slug: 'white-wine',
  },
]

const Index: NextPage = memo(() => {
  const selectedStore = useStore(s => s.store.selectedStore)
  const location = useStore(store => store.location)
  const { isLoggedIn } = useUser()
  const { isMobileApp, pushToken, platform } = useApp()

  const data = useProductsQuery<CoreProductEntity>({
    group: 'beer',
    store: selectedStore?.slug,
    region: selectedStore?.slug ? undefined : location.region,
  })

  const query = useQuery<any, any>(
    ['api/cms', BannersDocument, { first: 10, region: selectedStore?.region }],
    fetchGraphQL,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 30000,
    },
  )

  let images =
    query?.data?.banners?.nodes?.map(n => imageProxy(n.acf.imageDesktop.sourceUrl, { wp: true })) ||
    []

  data.data?.results.items.forEach(i => {
    images = images.concat(i.media.map(m => imageProxy(m.filename, { height: 240 })))
  })

  return (
    <LayoutMain noContainer images={images}>
      <Stack spacing={12} pb={20}>
        <HomeBanner />

        {/* <Text>isApp: {isMobileApp}</Text>
        <Text>platform: {platform}</Text> */}

        <Stack spacing={[8, 10, 12]} pt={[8, 10, 12]}>
          <SpecialsSection {...sections[0]} />

          <Advert position="HOME_TOP" />

          {!isLoggedIn && <HumpClubBanner />}

          {sections.slice(1).map((section, index) => (
            <Stack spacing={[8, 10, 12]}>
              <SpecialsSection
                {...section}
                key={section.slug}
                bg={index % 2 === 0 ? 'white' : 'gray.50'}
                borderTop={index % 2 === 0 ? 0 : '1px'}
                borderBottom={index % 2 === 0 ? 0 : '1px'}
                borderColor="gray.200"
                py={index % 2 === 0 ? null : [8, 10, 12]}
              />

              {index === 0 && <Advert position="HOME_MIDDLE" />}
              {index === 1 && <Advert position="HOME_BOTTOM" />}
            </Stack>
          ))}
        </Stack>
      </Stack>

      <TrendingKeywords />
    </LayoutMain>
  )
})

export const getServerSideProps = async (context: Context) => {
  const queryCache = new QueryCache()

  const ip = requestIp.getClientIp(context.req)
  const result = geoip.lookup(ip)
  let region = result?.region || 'VIC' // Default to VIC

  const validRegions = ['NSW', 'VIC', 'QLD', 'WA', 'SA', 'TAS', 'ACT', 'NT']

  if (!validRegions.includes(region)) {
    region = 'VIC'
  }

  const { selected_store } = parseCookies(context)

  // await Promise.all([
  //   queryCache.prefetchQuery(
  //     [
  //       'products/search',
  //       {
  //         params: {
  //           page: 1,
  //           limit: 12,
  //           store: selected_store,
  //           group: 'beer',
  //           region: selected_store ? undefined : region,
  //         },
  //       },
  //     ],
  //     backend,
  //     {
  //       staleTime: 30000,
  //     },
  //   ),

  //   queryCache.prefetchQuery<any, any>(
  //     ['api/cms', BannersDocument, { first: 10, region }],
  //     async (path, ...args) => {
  //       //@ts-ignore
  //       return fetchGraphQL('http://localhost:3000/api/cms', ...args)
  //     },
  //     { staleTime: 30000 },
  //     {},
  //   ),

  //   queryCache.prefetchQuery<any, any>(
  //     ['api/cms', SearchTagsDocument, { first: 5, region }],
  //     async (path, ...args) => {
  //       //@ts-ignore
  //       return fetchGraphQL('http://localhost:3000/api/cms', ...args)
  //     },
  //     { staleTime: 30000 },
  //     {},
  //   ),
  // ])

  return {
    props: {
      dehydratedState: dehydrate(queryCache),
    },
  }
}

export default Index
